.List-header {
  padding: 50px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 1000px;
}

.List-logo {
  width: 100px;
  margin-right: 10px;
  position: fixed;
  z-index: 5;
  left: 8;
  top: 60px;
  user-select: none;
  cursor: pointer;
}

.Title {
  height: 40px;
}

.ShoppingCartIcon {
  margin-left: 40px;
}

@keyframes List-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .List-logo {
    animation: List-logo-spin infinite 5s linear;
  }
}

@media (max-width: 768px) {
 
  .List-header {
    padding: 0;
    margin-top: 130px;
    margin-bottom: 40px;
    /* margin-left: 100px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    width: 75%;
    /* height: 150px; */
  }

  .List-logo {
    width: 70px;
    position: fixed;
    z-index: 5;
    left: 8%;
    top: 6%;
    user-select: none;
    cursor: pointer;
  }

  .Title-Mobile {
    width: 230px;
    margin-left: 0px;
  }
}