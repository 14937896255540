.List {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.back-to-top {
  color: var(--color-foreground);
  text-decoration: underline;
  font-size: 14px;
  position: fixed;
  right: 120px;
  bottom: 2px;
  z-index: 1000;
  cursor: pointer;
}

.back-to-top:hover {
  color: #656565;
}

.toggle {
  color: red;
}

.prodContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 744px;
  margin-bottom: 40px;
}

.summary{
  width: 30%;
}

.BannerContainer { 
  overflow: hidden; 
  width: 100%; 
  height: 50px;
  margin-bottom: 50px;
 }  

.banner { 
  white-space: nowrap; 
  display: inline-block; 
  /* height: 180px; */
  /* position: absolute;  */
  animation: rollBanner 10s linear infinite; 
 }

/* #BannerContainer {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  height: 150px;
} */
/*
#BannerContainer .scroll {
  display: inline-block;
  width: auto;
  white-space: nowrap;
  animation: rollBanner 16s linear infinite;
  height: 60%;
} */

#copyright {
  font-size: 80%;
  margin-bottom: 16px;
}

/* @keyframes rollBanner {
  0% {transform: translateX(100%);}
  100% {transform: translateX(-100%);}
} */
@keyframes rollBanner { 
  from { 
   transform: translateX(100%); 
  } 
  to { 
   transform: translateX(-100%); 
  } 
 }  

@media (max-width: 768px) {
  .List {
    width: auto;
  }

  .arrow {
    size: 80%;
  }

  .back-to-top {
    display: none;
  }

  .prodContainer {
    width: 100%;
  }

  .BannerContainer {
    /* overflow: hidden;  */
    /* width: 100%;  */
    height: auto;
    margin-bottom: 20px;
  }

  .banner {
    /* max-width: 100%; */
    /* height: 100%; */
    animation: rollBanner 15s linear infinite;
    /* white-space: nowrap;  */
    /* display: inline-block;  */
  }
}