@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
 --color-background: #FFD9D0;
 --color-foreground:#656565;
 --color-hover: #D5D5D5;
 background-color: var(--color-background);
 color: var(--color-foreground);
 font-size: 16px;
 text-decoration: none;
 letter-spacing: 0.05em;
 line-height: 20px;
 text-align: left;
}

body.dark {
  --color-background: #2C2921;
  --color-foreground: #98A495;
  --color-hover:#87AB7B;
  background-color: var(--color-background);
  color: var(--color-foreground);
}

@media (max-width: 768px) {
 body {
  width: auto;
  font-size: 14px;
 }
}

@media (prefers-color-scheme: dark) {
 body {
  --color-background: #2C2921;
  --color-foreground: #98A495;
  background-color: var(--color-background);
  color: var(--color-foreground);
 }
}