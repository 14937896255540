/* styles.css */ 
/* .light { 
background-color: white; 
color: black; 
} 

.dark { 
background-color: black; 
color: white; 
} */

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 744px;
  margin-bottom: 40px;
}

.product img {
  max-width: 600px;
  max-height: 350px;
  height: auto;
  margin-bottom: 10px;
}

.product h2 {
  text-align: center;
  font-size: 14px;
  color: var(--color-foreground);
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 2px;
}

.product p {
  color: var(--color-foreground);
  text-align: center;
  max-width: 80%;
}

a {
  color: var(--color-foreground);
  text-decoration: none;
}

a:hover {
  color: #d5d5d5;
}

@media (max-width: 768px) {
  .product{
    width: 100%;
    margin-bottom: 30px;
  }

  .product img {
    max-width: 200px;
    max-height: 200px;
    margin-bottom: 6px;
  }

  .product h2{
    font-size: 14px;
    margin-bottom: 0px;
    max-width: 70%;
  }

  .product p {
    max-width: 60%;
  }
}