.Landing {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 160px 0 0 256px;
}

.ZoeLogo {
  width: 600px;
  height: auto;
}

.Enter {
  font-size: 24px;
  font-style: italic;
  color: var(--color-foreground);
  text-decoration: none;
  margin: 45px 0 60px 5px;
  transition: color 0.3s;
  letter-spacing: 0.1em;
}

.Enter:hover {
  color: var(--color-hover);
}


@media (max-width: 768px) {
  .Landing {
    width: auto;
    margin: 100px 0 0 30px;
  }

  .ZoeLogo {
    width: 300px;
  }

  .Enter {
    font-size: 20px;
  }

}